/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {ViewImageModal} from '../../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/ViewImageModal'

type Props = {
  className: string
  data?: any
}

const TablesWidget13: React.FC<Props> = ({className, data = []}) => {
  const [showImageModal, setshowImageModal] = useState({
    show: false,
    clicked: '',
  })

  if (showImageModal.show) {
    return (
      <ViewImageModal
        close={() => setshowImageModal({show: false, clicked: ''})}
        images={showImageModal.clicked}
      />
    )
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Pick ups</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Latest 10 orders</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table /* className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' */
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            {/* begin::Table head */}
            <thead>
              <tr className='text-left text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th className='min-w-120px'>Plastic Station</th>
                <th className='min-w-120px'>Address</th>
                <th className='min-w-120px'>Rider</th>
                <th className='min-w-120px'>Item</th>
                <th className='min-w-120px'>Quantity</th>
                <th className='min-w-120px'>Images</th>
              </tr>
            </thead>
            <tbody>
              {data?.length &&
                data?.slice(0, 10).map((eachData, eachIndex) => {
                  return (
                    <tr key={eachIndex + 1} className='text-left'>
                      <td className='text-left min-w-100px'>
                        <a href='#' className='text-dark text-hover-primary fs-6'>
                          {eachData?.pickupInfo?.name?.slice(0, 80)}
                        </a>
                      </td>
                      <td className='text-left min-w-100px'>
                        <a href='#' className='text-success text-hover-primary d-block fs-6'>
                          {eachData?.pickupInfo?.address?.street?.slice(0, 50)}
                        </a>
                      </td>
                      <td className='text-left min-w-100px'>
                        <a href='#' className='text-dark text-hover-primary d-block fs-6'>
                          {eachData?.pickupInfo.agentName}
                        </a>
                      </td>
                      <td className='text-left min-w-100px'>
                        <a href='#' className='text-dark text-hover-primary d-block fs-6'>
                          {eachData.orderDetails[0]?.items.map(
                            (x, len) =>
                              `${x.itemName} ${
                                eachData.orderDetails[0]?.items.length - 1 === len ? '' : ' , '
                              }`
                          )}
                        </a>
                      </td>
                      <td className='text-left min-w-100px'>
                        <a href='#' className='text-dark text-hover-primary d-block fs-6'>
                          {eachData.orderDetails[0]?.items.map(
                            (x, len) =>
                              `${x.quantity} ${
                                eachData.orderDetails[0]?.items?.length - 1 === len ? '' : ' , '
                              }`
                          )}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {eachData?.hubInfo?.address?.country}
                        </span>
                      </td>
                      <td className='text-left min-w-100px'>
                        <div className='d-flex '>
                          <span
                            className='badge badge-light-primary fs-8 fw-bold'
                            onClick={() =>
                              eachData?.images?.length > 0
                                ? setshowImageModal({
                                    show: true,
                                    clicked: eachData.images,
                                  })
                                : ''
                            }
                          >
                            {' '}
                            click to view
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget13}
