import {FC} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type Props = {
  user?: any
  mapData: string
  number: number
}

const SerialNumber: FC<Props> = ({number}) => {
  const {state} = useQueryRequest()
  const {page, size = 10} = state
  return (
    <div className='d-flex flex-column'>
      {page === 0 ? number + 1 : (page + 1) * size + (number + 1) - (size === 100 ? 100 : 10)}
      {/* {page === 0 ? number + 1 : (page + 1) * size + number} */}
    </div>
  )
}

export {SerialNumber}
